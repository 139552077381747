$('.continue').prop('disabled', true);
$('.agree').change(function() {
$('.continue').prop('disabled', function(i, val) {
return !val;
}) }); 


$('.minus').click(function () {
    var $input = $(this).parent().find('input');
    var count = parseInt($input.val()) - 1;
    count = count < 1 ? 1 : count;
    $input.val(count);
    $input.change();
    return false;
});
$('.plus').click(function () {
    var $input = $(this).parent().find('input');
    $input.val(parseInt($input.val()) + 1);
    $input.change();
    return false;
});



function menuCheck() {
  var menu_attr = $("#menu").attr("hidden");
  var menu_icon = $("#menu_btn").attr("uk-icon");
  if (menu_attr !== 'hidden') {
    $('#menu_btn').attr('uk-icon', 'close');
  } else {
    $('#menu_btn').attr('uk-icon', 'menu');
  };
};


$(".nav_click").click(function(){
  menuCheck();
});

$('.btn_call').click(function() {
  $('.agree').prop('checked', false);
  $('.continue').prop('disabled', true);
});


$(".phone_input").mask("+7 (999) 999-9999");
// $(".mail").mask("hhhhhh@hhhhhh");


$(".formsubmit1").submit(function() { return false; });

$(".formsubmit1").submit(function () {
        var th = $(this);
        $.ajax({
            type: "POST",
            url: "mail.php", //Change
            data: th.serialize()
        }).done(function () {
            // код после успешной отправки формы
            UIkit.modal('#success').show();
            setTimeout(function() {
              UIkit.modal('#success').hide();
              $('input').val('');
              $('.agree').removeAttr("checked");     
              $('.continue').prop('disabled', true);
            }, 5000);
        });
        return false;
});

$(".formsubmit2").submit(function() { return false; });

$(".formsubmit2").submit(function () {
        var th = $(this);
        $.ajax({
            type: "POST",
            url: "mail.php", //Change
            data: th.serialize()
        }).done(function () {
            // код после успешной отправки формы
            UIkit.modal('#success2').show();
            setTimeout(function() {
              UIkit.modal('#success2').hide();
              $('input').val('');
              $('.agree').removeAttr("checked");     
              $('.continue').prop('disabled', true);
            }, 5000);
        });
        return false;
});

/**/

$("#range_value").on('input', function() {
    calc()
});

// calc();
    
function calc() {
var range_val_1 = 1 * $("#range_value").val();
var range_val_2 = 2961;
range_val_3_res = range_val_1 * range_val_2;

$("#summ").text( range_val_3_res.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
$("#range_summ").text( range_val_1.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );

// var range_val_3_res = 0
// if( range_val_3 < 100 ) {
//   range_val_3_res = 60;
// } else if( range_val_3 >= 100 && range_val_3 < 230 ) {
//   range_val_3_res = 150;
// } else if( range_val_3 >= 230 ) {
//   range_val_3_res = 300;
// }

// $("#amount3").text( range_val_3_res.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );

//

// var dohod  = 0;
// var rashod = 0;
// var pribil = 0;

// dohod  = range_val_1 * range_val_2;
// rashod = dohod * 0.74 + dohod * 0.03 +  718800 + range_val_3_res * 1000;
// pribil = dohod - rashod;

// $("#dohod").text( dohod.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
// $("#rashod").text( rashod.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
// $("#pribil").text( pribil.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );

}


   












